import React from 'react'
import styled from 'styled-components'
import Cookies from 'universal-cookie';
import { Heading, Text, BaseLayout } from '@pancakeswap-libs/uikit'
import { useQueryParam, StringParam } from 'use-query-params';
import useI18n from 'hooks/useI18n'
import Page from 'components/layout/Page'
import rot13 from '../../utils/encode'
import FarmStakingCard from './components/FarmStakingCard'
import { isAddress } from '../../utils/web3'

import LotteryCard from './components/LotteryCard'
import CakeStats from './components/CakeStats'
import TotalValueLockedCard from './components/TotalValueLockedCard'
import TwitterCard from './components/TwitterCard2'
import Card1 from './components/Card1';
import EarnAPY from './components/EarnAPY';

const Hero = styled.div`
  align-items: center;
  background-image: url('/images/egg/3.png');
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 32px;
  padding-top: 116px;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.lg} {
    background-image: url('/images/egg/left-pancake.svg'), url('/images/egg/right-pancake.svg');
    background-position: left center, right center;
    height: 165px;
    padding-top: 0;
  }
`

const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 48px;

  & > div {
    grid-column: span 6;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 6;
    }
  }
`

const CTACards = styled(BaseLayout)`
  align-items: start;
  margin-bottom: 32px;

  & > div {
    grid-column: span 6;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 4;
    }
  }
`

const NewCard = styled.div`
  display: grid;
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 100% 100%;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: 35% 65%;
  }

`

// const Home: React.FC = () => {
  const Home = () => {
  const TranslateString = useI18n()
  const cookies = new Cookies();
  const [ref, setNum] = useQueryParam('ref', StringParam);
  if(ref) {
    if(isAddress(rot13(ref))) {
      cookies.set("ref", ref)
    }
  }

    React.useEffect(() =>{
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
      script.async = true;
      script.innerHTML = JSON.stringify({ "symbols": [
        { 
          "description": "Solana", 
          "proName": "COINBASE:SOLUSD" 
        }, 
        { 
          "description": "Tron", 
          "proName": "BINANCE:TRXUSD" 
        }, 
        { 
          "description": "Binance", 
          "proName": "BINANCE:BNBUSD" 
        }, 
        { 
          "description": "OraCoin Foundation", 
          "proName": "PANCAKESWAP:ORACUSDT_8B98AE" 
        }, 
        { 
          "description": "USDT TRC20", 
          "proName": "BINANCE:TRXUSDT" 
        }, 
        { 
          "description": "USDT BEP20", 
          "proName": "BINANCE:BNBUSDT" 
        }, 
        { 
          "description": "Bitcoin", 
          "proName": "COINBASE:BTCUSD" 
        } 
      ],
      "showSymbolLogo": true,
      "colorTheme": "light",
      "isTransparent": false,
      "displayMode": "adaptive",
      "locale": "en"})
      document.getElementById("myContainer2").appendChild(script);
    },[])

  return (
    <>
          <div id="myContainer2" style={{width:'100%'}}>
        <div className="tradingview-widget-container">
           <div className="tradingview-widget-container__widget" />
        </div>
     </div>
    <Page>
      {/* <Hero>
        <Heading as="h1" size="xl" mb="24px" color="secondary">
          {TranslateString(575, 'Super Shiba Finance')}
        </Heading>
        <Text>{TranslateString(575, 'The next generation of yield farming on BSC')}</Text>
      </Hero> */}

      <div>
        <br />
          <NewCard>
        {/* <Card1 /> */}
        <FarmStakingCard />
        <TwitterCard/>
        </NewCard>
        <br /> <br />
        <NewCard>
          {/* <CakeStats /> */}
          {/* <TotalValueLockedCard /> */}
          <CakeStats />
          {/* <EarnAPY /> */}
          <TotalValueLockedCard />
        </NewCard>
      </div>
    </Page>
    </>
  )
}

export default Home
