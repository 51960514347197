import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    href:'https://dex.oracoin.io/'
    // items: [
    //   {
    //     label: 'Exchange',
    //     href: '',
    //   },
    //   {
    //     label: 'Liquidity',
    //     href: 'https://dex.oracoin.io/#/pool',
    //   },
    // ],
  },
  {
    label: 'Liquidity',
    icon: 'TradeIcon',
    href:'https://dex.oracoin.io/#/pool'
  },
  {
    label: 'Farming ORAC',
    icon: 'FarmIcon',
    href: '/farms',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Vaults',
  //   icon: 'PoolIcon',
  //   href: '/vaults',
  // },
  {
    label: 'Referrals',
    icon: 'GroupsIcon',
    href: '/referrals',
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    href: '/charts',
  },
  // {
  //   label: 'Yield 1% USDT daily (coming soon)',
  //   icon: 'MoreIcon',
  //   href:'/coming-soon'
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://pancakeswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://pancakeswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://pancakeswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://pancakeswap.info/accounts',
  //     },
  //   ],
  // },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Leaderboard',
        href: '/table',
      },
    ],
  },
]

export default config
