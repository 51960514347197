import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.background};
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    // background: url("/Trebo-banner-Splash.jpg") center center / cover no-repeat fixed;
    img {
      height: auto;
      max-width: 100%;
    }
  }


.customcolumn {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.customrow:after {
  content: "";
  display: table;
  clear: both;
}

`

export default GlobalStyle
