import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'ORAC-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x275f7A2288D622fDB0B1D13321E74e5071fAb290',
    },
    tokenSymbol: 'ORAC',
    tokenAddresses: {
      97: '',
      56: '0xb09491042d6A502c7601C9C7379cFac607BC8FBb',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  // {
  //   pid: 2,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'WBNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // bnb - busd lp
  //   },
  //   tokenSymbol: 'WBNB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ORAC',
    lpAddresses: {
      97: '',
      56: '0x275f7A2288D622fDB0B1D13321E74e5071fAb290', // sshiba - wbnb lp
    },
    tokenSymbol: 'ORAC',
    tokenAddresses: { // 
      97: '',
      56: '0xb09491042d6A502c7601C9C7379cFac607BC8FBb',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'ORAC-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x8b98aEcDF91F6711C54c17edA1D5c071Cf44263B',
    },
    tokenSymbol: 'ORAC',
    tokenAddresses: {
      97: '',
      56: '0xb09491042d6A502c7601C9C7379cFac607BC8FBb',
    },
    quoteTokenSymbol: QuoteToken.UST,
    quoteTokenAdresses: contracts.usdt,
  },
  // {
  //   pid: 6,
  //   risk: 5,
  //   lpSymbol: 'SIBT-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x68D11ffB6957a7e921ADAd3f4a9e6C7489F51803',
  //   },
  //   tokenSymbol: 'SIBT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xd10f5AD339eF5184A13AD473944a2f267A2365Af',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 7,
  //   risk: 5,
  //   lpSymbol: 'SIBT-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xdBbc6c09bA8b54576537E65DA610D646bE5cc07D',
  //   },
  //   tokenSymbol: 'SIBT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xd10f5AD339eF5184A13AD473944a2f267A2365Af',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 3,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'MFTN',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x6b012785a4A423f28717e449E3b2B3187b62b1A2', // sshiba - wbnb lp
  //   },
  //   tokenSymbol: 'MFTN',
  //   tokenAddresses: { // 
  //     97: '',
  //     56: '0x7Be9D7eF4f70aAEfd7dbb99985A8F0aAf4C75CE1',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 4,
  //   risk: 5,
  //   lpSymbol: 'AGILA-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xD8E7b5bDf83Ef56856f270Ffc953e5fBe58014EB',
  //   },
  //   tokenSymbol: 'AGILA',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xC4953Cf41B54abd465135ac4DC2b3d4B4FA9393b',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 5,
  //   risk: 5,
  //   lpSymbol: 'AGILA-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xD8E7b5bDf83Ef56856f270Ffc953e5fBe58014EB',
  //   },
  //   tokenSymbol: 'AGILA',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xC4953Cf41B54abd465135ac4DC2b3d4B4FA9393b',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  
  // {
  //   pid: 3,
  //   risk: 5,
  //   lpSymbol: 'BSTOCK-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x72856674382e514dcbecb18d9fed1004455c1882',
  //   },
  //   tokenSymbol: 'BSTOCK',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xEb312B80ac7318A1a1407Da305cED0e01f2876B2',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 4,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'EXELERO',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x016e0b2Eb8C6911E233b4209b5728838150F6D7C', // trx - busd lp
  //   },
  //   tokenSymbol: 'EXELERO',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x363565D16336081013dbe9C65370Cd65209BB5c0',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },

]

export default farms
