import React from 'react'
import { Card, CardBody, Heading, Button } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js/bignumber'
import styled from 'styled-components'
import { Timeline } from 'react-twitter-widgets'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTotalSupply, useBurnedBalance } from 'hooks/useTokenBalance'
import useI18n from 'hooks/useI18n'
// import WheelComponent from "react-wheel-of-prizes";
import { Wheel } from 'react-custom-roulette'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import Web3 from 'web3'
import { ethers } from 'ethers'
import UnlockButton from 'components/UnlockButton'
import ABI from './abi.json'
import tokenabi from '../../../config/abi/erc20.json'


const StyledTwitterCard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
`

const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 8px;
`

const Parentt = styled.div`
     width: 350px;
     height: 350px;
     > div {
          height: 100%;
          width: 100%;
          max-height: unset;
          max-width: unset;
     }
`

const data = [
    { option: '1 ORAC', style: { backgroundColor: 'forestgreen'} },
    { option: '5 ORAC', style: { backgroundColor: 'blue' } },
    { option: '0 ORAC', styles: {backgroundColor:'red'} },
    { option: '500 ORAC', style: { backgroundColor: 'deeppink' } },
    { option: '0 ORAC', styles: {backgroundColor:'red'} }

  ]
  


  // const DepositModal = ({ max, onConfirm, onDismiss, tokenName = '', addLiquidityUrl }) => {

const TwitterCard = () => {
    const [mustSpin, setMustSpin] = React.useState(false);
    const [prizeNumber, setPrizeNumber] = React.useState(0);

  const TranslateString = useI18n()



  const { account, ethereum } = useWallet()

  const contractAddr = "0x8590f0C5e68E6C935de45519211225F8fB4c113c"
  const [allowance, setAllowance] = React.useState('100')
  const [loading, setLoading] = React.useState(false)


  const handleSpinClick = async() => {
    try{
      if(account && ethereum){
        setLoading(true)
        console.log('before res')

        // @ts-ignore
        const web3 = new Web3(ethereum)

        // @ts-ignore
        const contractToken = new web3.eth.Contract(tokenabi, '0xb09491042d6a502c7601c9c7379cfac607bc8fbb');
        const localBalance = await contractToken.methods.balanceOf(account).call()
        console.log('local is ', parseFloat(localBalance))
        if(parseFloat(localBalance) < 1e18){
          alert('You must hold at least one token');
          setLoading(false);
          return;
        }

              // @ts-ignore
        const contract = new web3.eth.Contract(ABI, contractAddr);
        console.log('res 2 is ', contract)
        const realRes= await contract.methods.spin().send({
          from: account
        })
        console.log('res is ', realRes, realRes.events.SpinResult.returnValues.reward)

        //         emit SpinResult(msg.sender, randomNumber, reward);


        
      // if (!mustSpin) {
        const newPrizeNumber = parseFloat(realRes.events.SpinResult.returnValues.reward)
        let localPrice;

        switch(newPrizeNumber){
          case 500*1e18:
            localPrice = 3
            break;
          case 5*1e18:
            localPrice = 1;
            break;
          case 1*1e18:
            localPrice = 0
            break;
          default:
            localPrice = 2;
            break;
        }
        setPrizeNumber(localPrice);
        setMustSpin(true);
      // }
      }
      else{
        alert('Connect first')
        setLoading(false)
      }
    }catch(err){
      console.log('err is ' , err)
      setLoading(false)
    }
  }
  const [isowner, setIsowner] = React.useState(true)

  React.useEffect(() => {
    
    const checkAllowance = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed1.ninicoin.io")
      );      
      
            // @ts-ignore
      const contract = new web3.eth.Contract(tokenabi, '0xb09491042d6a502c7601c9c7379cfac607bc8fbb');
      const localAllowance = await contract.methods.allowance(account, contractAddr).call()
      console.log('all is ', localAllowance)
      setAllowance(localAllowance) 
      setLoading(false)

                    // @ts-ignore
      const contract2 = new web3.eth.Contract(ABI, contractAddr);
      const owner = await contract2.methods.owner().call()
      if(owner.toLowerCase() === account.toLowerCase()){
        setIsowner(true)
      } else {
        setIsowner(false)
      }


    }

    if(account){
      checkAllowance()
    }

  },[account])

  const approveSpending = async() => {
    try{
      setLoading(true)
      // @ts-ignore
      const web3 = new Web3(ethereum)
            // @ts-ignore
      const contract = new web3.eth.Contract(tokenabi, '0xb09491042d6a502c7601c9c7379cfac607bc8fbb');
    
      await contract.methods.approve(contractAddr, ethers.constants.MaxUint256).send({
        from: account
      })
      
      setAllowance('1000')
      setLoading(false)
    }catch(err){
      setLoading(false)
    }

  }


  const [amount, setAmount] = React.useState('')

  const handleWithdraw = async() => {
    try{
      setLoading(true)
      // @ts-ignore
      const web3 = new Web3(ethereum)



      // @ts-ignore
      const amountInWei= await web3.utils.toWei(amount ,"ether");
      console.log('amount is ', amountInWei)

      // @ts-ignore
      const contract = new web3.eth.Contract(ABI, contractAddr);
      await contract.methods.withdraw(amountInWei.toString()).send({
        from: account
      })

      setAmount('')
      setLoading(false)
    }catch(err){
      setLoading(false)
    }

  }


  const newRender = () => {
    if(allowance === "0"){
      return <Button disabled={loading} style={{margin:'0 auto', display:'block'}} type="button" onClick={approveSpending}>Approve Spending</Button>
    } 
      return (
        <div>
 <Button disabled={mustSpin || loading} style={{margin:'0 auto', display:'block'}} type="button" onClick={handleSpinClick}>SPIN (1 ORAC)</Button>

 {isowner === true ? <div>
  <input value={amount} onChange={(event) => setAmount(event.target.value)} type="number" placeholder='amount e.g 100' style={{marginTop:"1rem", width:'100%'}} />
  <Button disabled={loading} style={{margin:'0 auto', marginTop:'1rem', display:'block'}} variant='danger' type="button" onClick={handleWithdraw}>Withdraw as Owner</Button>

   </div> : null}
                      {/* <br />                      <br />

                      <Button
      id="harvest-all"
      disabled={pendingTx}
      // onClick={BetSmartContract}
      onClick={onPresentDeposit}
      fullWidth
    >
      Bet 
    </Button> */}
        </div>
      )

  }

  return (
    <div style={{margin:'0 auto'}}>
      {/* <CardBody>
        <Heading size="xl" mb="24px">
          {TranslateString(1, 'Announcements')}
        </Heading> */}
        <Parentt>
    <Wheel
      mustStartSpinning={mustSpin}
      prizeNumber={prizeNumber}
      startingOptionIndex={prizeNumber}
      data={data}
      backgroundColors={['red', '#df3428']}
      textColors={['#ffffff']}
      perpendicularText
      innerRadius={15}
      spinDuration={1}
      onStopSpinning={() => {
        setMustSpin(false);
        setLoading(false)
      }}
    />
</Parentt>      {/* </CardBody> */}

                     {account ?  newRender()  :             <UnlockButton fullWidth />}


    </div>
  )
}

export default TwitterCard
