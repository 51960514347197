import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   tokenName: 'CAKE',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://pancakeswap.finance/',
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  {
    sousId: 884,
    tokenName: 'Trebo',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x37099e0eC600b55803c28683A6d668E4da4Ff34D',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x281Ee071cbca084025D3Af76c7004dfCcd5d8530',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://trebo.money',
    harvest: true,
    tokenPerBlock: '1.1574074074074074',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "0%"
  },
  {
    sousId: 885,
    tokenName: 'ONYX (POOL 1)',
    stakingTokenName: QuoteToken.ONYX,
    stakingTokenAddress: '0xBbc23b2C48D9514cD5FBC52301984A8489f7E0AE',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xA8F79cc4A426b85D37606EAc6877F32DfCee78fD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://trebo.money',
    harvest: true,
    tokenPerBlock: '1.1574074074074074',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "0%"
  },
  {
    sousId: 886,
    tokenName: 'ONYX (POOL 2)',
    stakingTokenName: QuoteToken.ONYX,
    stakingTokenAddress: '0xBbc23b2C48D9514cD5FBC52301984A8489f7E0AE',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x1E3937b6C606C0c0Dd670068504Bc89153221E35',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://trebo.money',
    harvest: true,
    tokenPerBlock: '1.1574074074074074',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "0%"
  },
  {
    sousId: 887,
    tokenName: 'ONYX (POOL 3)',
    stakingTokenName: QuoteToken.ONYX,
    stakingTokenAddress: '0xBbc23b2C48D9514cD5FBC52301984A8489f7E0AE',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x7c916D69B31e9892C0ACc978Af9A230C19491450',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://trebo.money',
    harvest: true,
    tokenPerBlock: '1.1574074074074074',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "0%"
  },
  // {
  //   sousId: 888,
  //   tokenName: 'ONYX (ANGEL INVESTOR)',
  //   stakingTokenName: QuoteToken.ONYX,
  //   stakingTokenAddress: '0xBbc23b2C48D9514cD5FBC52301984A8489f7E0AE',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0x6AaD4dB4Ae1e8DAFCF4873e4D3dD515e47D7ec61',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trebo.money',
  //   harvest: true,
  //   tokenPerBlock: '1.1574074074074074',
  //   sortOrder: 999,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   fee: "0%"
  // },
]

export default pools
