import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { QueryParamProvider } from 'use-query-params';
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'
import Home from './views/Home'
// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
// const Home = lazy(() => import('./views/Home'))
const Charting = lazy(() => import('./views/Charting'))
const Farms = lazy(() => import('./views/Farms'))
// const Farms2 = lazy(() => import('./views/Farms2'));
const Lottery = lazy(() => import('./views/Lottery'))
const Pools = lazy(() => import('./views/Pools'))
const Referral = lazy(() => import('./views/Referral'))
// const Ifos = lazy(() => import('./views/Ifos'))
const Tables = lazy(() => import('./views/Tables/index'))

const NotFound = lazy(() => import('./views/NotFound'))
// const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<> </>}>
          <Switch>
          {/* <Route path="/" exact>
              <div style={{textAlign:'center', color:'white', marginTop:"5%"}}>
                <h1>
                  SITE OPERATIONS TEMPORARILY SUSPENDED
                </h1>
                <br />
                <h3 style={{fontSize:'25px'}}>
                The site is temporarily unavailable due to scheduled maintenance.
                </h3> */}
                {/* <h3 style={{fontSize:'25px'}}>If you are participant of this DeFi product, don&apos;t worry, funds are SAFU! Operations will 
                  resume on payment coverage by the site owner.
                </h3>
                 <br />
                <p>For more information contact <a href="https://forkswap.org" target="_blank" rel="noreferrer">forkswap.org</a></p> */}
              {/* </div>
            </Route> */}
            <Route path="/" exact>
            {/* @ts-ignore */}
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            {/* <Route path="/pools">
              <Farms tokenMode />
            </Route> */}
            <Route path="/referrals">
                <Referral />
              </Route>
            <Route path="/charts">
                <Charting />
            </Route>
            <Route path="/table">
              <Tables />
            </Route>
            {/* <Route path="/vaults">
              <Pools />
            </Route>

              <Route path="/presale">
             <Ifos />
             </Route> */}


{/* 
            <Route path="/lottery">
              <Farms2 />
            </Route> */}

                        {/* <Route path="/presale"> 
              <Ifos /> 
             </Route> */}
            {/* <Route path="/pools"> */}
            {/*  <Pools /> */}
            {/* </Route> */}





            {/* <Route path="/nft"> */}
            {/*  <Nft /> */}
            {/* </Route> */}
            {/* Redirect */}
            {/* <Route path="/staking"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* <Route path="/syrup"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <NftGlobalNotification />
      </QueryParamProvider>
    </Router>
  )
}

export default React.memo(App)
