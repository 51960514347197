import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Heading, Card, CardBody, Button } from '@pancakeswap-libs/uikit'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import BigNumber from 'bignumber.js'
import useI18n from 'hooks/useI18n'
import { useAllHarvest } from 'hooks/useHarvest'
import useFarmsWithBalance from 'hooks/useFarmsWithBalance'
import UnlockButton from 'components/UnlockButton'
import CakeHarvestBalance from './CakeHarvestBalance'
import CakeWalletBalance from './CakeWalletBalance'
import { usePriceCakeBusd, usdtToPHP, usdtToPHP2 } from '../../../state/hooks'
import useTokenBalance from '../../../hooks/useTokenBalance'
import { getCakeAddress } from '../../../utils/addressHelpers'
import useAllEarnings from '../../../hooks/useAllEarnings'
import { getBalanceNumber } from '../../../utils/formatBalance'

const StyledFarmStakingCard = styled(Card)`


  background-image: url('/images/MFTN_ICON.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 125px; // 150px;
  min-height: 376px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  margin-bottom:2%;
`

const Block = styled.div`
  margin-bottom: 16px;
`

const CardImage = styled.img`
  margin-bottom: 16px;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 14px;
`

const Actions = styled.div`
  margin-top: 24px;
`

const FarmedStakingCard = () => {
  const [pendingTx, setPendingTx] = useState(false)
  const { account } = useWallet()
  const TranslateString = useI18n()
  const farmsWithBalance = useFarmsWithBalance()
  const cakeBalance = getBalanceNumber(useTokenBalance(getCakeAddress()))
  const eggPrice = usePriceCakeBusd().toNumber()
  const allEarnings = useAllEarnings()
  const earningsSum = allEarnings.reduce((accum, earning) => {
    return accum + new BigNumber(earning).div(new BigNumber(10).pow(18)).toNumber()
  }, 0)
  const balancesWithValue = farmsWithBalance.filter((balanceType) => balanceType.balance.toNumber() > 0)

  const { onReward } = useAllHarvest(balancesWithValue.map((farmWithBalance) => farmWithBalance.pid))

  const harvestAllFarms = useCallback(async () => {
    setPendingTx(true)
    try {
      await onReward()
    } catch (error) {
      // TODO: find a way to handle when the user rejects transaction or it fails
    } finally {
      setPendingTx(false)
    }
  }, [onReward])


  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const [symbol, setSymbol] = React.useState('₱')
const phpPrice = usdtToPHP()
const phpPrice2 = usdtToPHP2()





React.useEffect(() => {
  const x = localStorage.getItem('inphp')

  if(x === '0'){
    setSymbol('د.إ ')
  } else {
    setSymbol('$')
  }
}, [symbol])

  return (
    <StyledFarmStakingCard>
      <CardBody>
        {/* <Heading size="xl" mb="24px">
          Farms & Staking
        </Heading> */}
        {/* <img src="/images/MFTN_ICON.png" style={{width:'100%', maxWidth:'95px'}} alt="slushy icon" /> */}
        <h2 style={{fontSize:'38px', textAlign:'center'}}>
          {/* <img src="/images/slushyicon.png" width="24px" alt="slushy icon" /> */}
        Farming ORAC
        {/* <img src="/images/slushyicon.png" width="24px" alt="slushy icon" /> */}

        </h2>
        <br />
        {/* <CardImage src="/images/egg/2.png" alt="cake logo" width={64} height={64} /> */}
        {/* <Block>
          <h2 style={{textAlign:'center'}}>Starting Post Presale</h2>
        </Block> */}
        <Block>
          <Label>{TranslateString(543, 'ORAC to Harvest')} للحصاد</Label>
          <CakeHarvestBalance earningsSum={earningsSum}/>
          <Label>= ${ numberWithCommas((eggPrice * earningsSum).toFixed(2))} / ₱{numberWithCommas((eggPrice*Number(phpPrice2) * earningsSum).toFixed(2))} / د.إ {numberWithCommas((eggPrice*Number(phpPrice) * earningsSum).toFixed(2))}
            
            {/* {symbol}{symbol === '$' ? numberWithCommas((eggPrice * earningsSum).toFixed(2)) :  numberWithCommas((eggPrice*Number(phpPrice) * earningsSum).toFixed(2))} */}
            </Label>
        </Block>
        <Block>
          <Label>{TranslateString(543, 'ORAC in Wallet')} في المحفظة</Label>
          <CakeWalletBalance cakeBalance={cakeBalance} />
          <Label>= ${numberWithCommas((eggPrice * cakeBalance).toFixed(2))} / ₱{numberWithCommas((eggPrice*Number(phpPrice2) * cakeBalance).toFixed(2))} / د.إ{numberWithCommas((eggPrice*Number(phpPrice) * cakeBalance).toFixed(2))}
            
            {/* {symbol}{ symbol === '$' ? numberWithCommas((eggPrice * cakeBalance).toFixed(2)) : numberWithCommas((eggPrice*Number(phpPrice) * cakeBalance).toFixed(2))} */}</Label>
        </Block>
        <Actions>
          {account ? (
            <Button
              id="harvest-all"
              disabled={balancesWithValue.length <= 0 || pendingTx}
              onClick={harvestAllFarms}
              fullWidth
            >
              {pendingTx
                ? TranslateString(547, 'Collecting ORAC')
                : TranslateString(999, `Harvest all (${balancesWithValue.length}) حصاد كل شيء                `)}
            </Button>
          ) : (
            <UnlockButton fullWidth />
          )}
        </Actions>
      </CardBody>
    </StyledFarmStakingCard>
  )
}

export default FarmedStakingCard
