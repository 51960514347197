export default {
  cake: {
    56: '0xb09491042d6A502c7601C9C7379cFac607BC8FBb', //  0x7Be9D7eF4f70aAEfd7dbb99985A8F0aAf4C75CE1
    97: '',
  },
  masterChef: {
    56: '0x4fe7F8EB041DdB92C59f2C8975eBcA679B8f3398', //  0xE027EF416D9B04d89F7cCB6a266813277C74d4d4  0xabF912743Be9fA077d623BB6Fa34F001cE473cdD
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0xB1fE7068bF3A06E4533a203D0b1b86a99F1C091D',
    97: '',
  },
  lotteryNFT: {
    56: '0x5b0E7B3E551E33475D25A289F42d0497cAAD1e4e',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '',
  },
  referral: {
    56: '0x5053A9F4fe5595CA50e1eA88BFCD418A5c05c910', //  0x26786286943080404644f307f946e215bfb673F2
    97: '',
  }
}
