import React from 'react'
import { Card, CardBody, Heading, Text } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js/bignumber'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTotalSupply, useBurnedBalance } from 'hooks/useTokenBalance'
import useI18n from 'hooks/useI18n'
import { getCakeAddress } from 'utils/addressHelpers'
import CardValue2 from './CardValue2'
import { useFarms, usePriceCakeBusd, usdtToPHP, usdtToPHP2 } from '../../../state/hooks'

const StyledCakeStats = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  width:100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  min-height: 100px;
  margin-bottom:2%;

`

const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 8px;
`

const CakeStats = () => {
  const TranslateString = useI18n()
  const totalSupply = useTotalSupply()
  const burnedBalance = useBurnedBalance(getCakeAddress())
  const farms = useFarms();
  const eggPrice = usePriceCakeBusd();
  const circSupply = totalSupply ? totalSupply.minus(burnedBalance) : new BigNumber(0);
  const cakeSupply = getBalanceNumber(circSupply);
  const marketCap = eggPrice.times(circSupply);
  const phpPrice = usdtToPHP()
  const phpPrice2 = usdtToPHP2()

  let eggPerBlock = 8;
  if(farms && farms[0] && farms[0].eggPerBlock){
    eggPerBlock = new BigNumber(farms[0].eggPerBlock).div(new BigNumber(10).pow(18)).toNumber();
  }

  const [symbol, setSymbol] = React.useState('₱')


  React.useEffect(() => {
    const x = localStorage.getItem('inphp')

    if(x === '0'){
      setSymbol('د.إ ')
    } else {
      setSymbol('$')
    }
  }, [symbol])


  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  return (
    <StyledCakeStats>
      <CardBody>
        <Heading size="lg" mb="24px">
          {TranslateString(533, 'ORAC  Stats')} <span style={{float:"right"}}>احصائيات</span>
        </Heading>
        {marketCap ?         <Row >
          <Text fontSize="14px">{symbol === '$' ? 'USD' : 'AED'} Market Cap</Text>
          
          <p style={{fontSize:'14px', fontWeight:'bold'}}>  {symbol} {symbol === '$' ? numberWithCommas(getBalanceNumber(marketCap).toFixed(0)) : numberWithCommas((getBalanceNumber(marketCap)*Number(phpPrice)).toFixed(0))} </p>
        </Row> : null}
        <Row >
          <Text fontSize="14px">{TranslateString(1, 'ORAC  in Circulation')}</Text>
          {cakeSupply ? <CardValue2 fontSize="14px" value={cakeSupply} decimals={0} /> : null}
        </Row>
        <Row >
          <Text fontSize="14px">{TranslateString(1, 'ORAC  Burned')}</Text>
          <CardValue2 fontSize="14px" value={getBalanceNumber(burnedBalance)} decimals={0} />
        </Row>
        {/* style={{background:'#7772ff', color:'white',padding:'3px',borderRadius:'25px'}} */}
        <Row >
          <Text fontSize="14px">{TranslateString(1, 'Distributed ORAC /block')}</Text>
          <Text bold fontSize="14px" >{eggPerBlock}</Text>
        </Row>
        <Row >
          <Text fontSize="14px">
            {/* <img width="20px" src="/images/egg/9.png" alt="logo" />  */}
            {TranslateString(1, 'ORAC  Value')}</Text>
          {/* @ts-ignore */}
          <Text bold fontSize="14px" >${parseFloat(eggPrice.toString()).toFixed(2)} / ₱ {parseFloat(parseFloat(eggPrice.toString()) * parseFloat(phpPrice2)).toFixed(2)} / د.إ {parseFloat(parseFloat(eggPrice.toString()) * parseFloat(phpPrice)).toFixed(2)}  </Text>
        </Row>
        {/* <Row>
          <Text fontSize="14px">{TranslateString(536, 'Total Minted')}</Text>
          {totalSupply && <CardValue fontSize="14px" value={getBalanceNumber(totalSupply)} decimals={0} />}
        </Row> */}
      </CardBody>
    </StyledCakeStats>
  )
}

export default CakeStats
