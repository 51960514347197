import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'
import useGetPriceData from '../components/Menu/getPrice'
import useGetPriceCustom from '../components/Menu/getPriceCustom'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}


// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  // const pid = 2 // BUSD-BNB LP
  const pid = 0;
  const farm = useFarmFromPid(pid)
    // @ts-ignore  
    const priceData = useGetPriceData()

     // @ts-ignore  
    const priceData2 = new BigNumber(priceData)
    // const pricePHP = usdtToPHP()

    const priceData3 = priceData2.times(Number(1))
    
    return priceData3
}

export const usePriceCakeBusd = (): BigNumber => {
  // const pid = 1 // CAKE-BNB LP
  const pid = 0;
  // const bnbPriceUSD = usePriceBnbBusd()

  let priceData = useGetPriceData()


  // @ts-ignore  
  priceData = new BigNumber(priceData)



  const farm = useFarmFromPid(pid)
  // @ts-ignore
  localStorage.setItem('poolTp', priceData.times(farm.tokenPriceVsQuote).toString());



  const pricePHP = usdtToPHP()
  localStorage.setItem('PHP' , pricePHP)
  // console.log('price php is ', pricePHP)

  //  @ts-ignore
  return farm.tokenPriceVsQuote ? priceData.times(farm.tokenPriceVsQuote) : ZERO
  
}

// export const useTotalValue = (): BigNumber => {
//   const farms = useFarms();
//   const bnbPrice = usePriceBnbBusd();
//   const cakePrice = usePriceCakeBusd();
//   let value = new BigNumber(0);
//   for (let i = 0; i < farms.length; i++) {
//     const farm = farms[i]
//     if (farm.lpTotalInQuoteToken) {
//       let val;
//       if (farm.quoteTokenSymbol === QuoteToken.BNB) {
//         val = (bnbPrice.times(farm.lpTotalInQuoteToken));
//       }else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
//         val = (cakePrice.times(farm.lpTotalInQuoteToken));
//       }else{
//         val = (farm.lpTotalInQuoteToken);
//       }
//       value = value.plus(val);
//     }
//   }
//   return value;
// }
export const useTotalValue = (): BigNumber => {
  const farms = useFarms();
  const bnbPrice = usePriceBnbBusd();
  const cakePrice = usePriceCakeBusd();
  let value = new BigNumber(0);
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val;
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = (bnbPrice.times(farm.lpTotalInQuoteToken));
      }else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
        val = (cakePrice.times(farm.lpTotalInQuoteToken));
      }else{
        val = (farm.lpTotalInQuoteToken);
      }
      value = value.plus(val);
    }
  }
  return value;
}


export const usdtToPHP = () => {
  
//     const myHeaders = new Headers();
// myHeaders.append("apikey", "6mkXju7pYcVN4pl8ZsCVBhCiY4B3r46A");

// const requestOptions = {
//   method: 'GET',
//   redirect: 'follow',
//   headers: myHeaders
// };

// // @ts-ignore
// fetch("https://api.apilayer.com/exchangerates_data/convert?to=PHP&from=USD&amount=1", requestOptions)
//   .then(response => response.text())
//   .then(result => {
//     // @ts-ignore
//     // console.log('res is ', JSON.parse(result).result)
//     return (JSON.parse(result).result)
    
//   })
//   .catch(error => {console.log('error', error)
//   return '0'
// });
  return '3.67'
}


export const usdtToPHP2 = () => {
  
  //     const myHeaders = new Headers();
  // myHeaders.append("apikey", "6mkXju7pYcVN4pl8ZsCVBhCiY4B3r46A");
  
  // const requestOptions = {
  //   method: 'GET',
  //   redirect: 'follow',
  //   headers: myHeaders
  // };
  
  // // @ts-ignore
  // fetch("https://api.apilayer.com/exchangerates_data/convert?to=PHP&from=USD&amount=1", requestOptions)
  //   .then(response => response.text())
  //   .then(result => {
  //     // @ts-ignore
  //     // console.log('res is ', JSON.parse(result).result)
  //     return (JSON.parse(result).result)
      
  //   })
  //   .catch(error => {console.log('error', error)
  //   return '0'
  // });
    return '56.64'
  }

export const useGetApiPrice = (token: string) => {
  // const prices = useGetApiPrices()


  // if (!prices) {
  //   return null
  // }
  let toReturn;

  const whatis = localStorage.getItem('inphp')

  switch(token){
    case "ORAC":
      toReturn = whatis === '1' ? parseFloat('56.64') : parseFloat('1');
      break;
    default:
      toReturn = null
      break;
  }
  return toReturn
  // return prices[token.toLowerCase()]
}